
import { defineComponent, onMounted, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Actions, Modules } from "@/store/enums/AdminStoreEnums";
import { Modules as MainModules } from "@/store/enums/StoreEnums";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { formatDateTime } from "@/core/helpers/date";
import Pagination from "@/components/Pagination.vue";

export default defineComponent({
  name: "AdminWorldSessions",
  components: { Pagination },
  setup() {
    const store = useStore();
    const route = useRoute();

    let search = ref("");
    let currentPageURL = ref("");
    let live = ref(false);

    live.value = route.query?.live ? true : false;

    let isLoading = computed(() => {
      return store.getters[Modules.WORLD_SESSION + "getIsLoading"];
    });
    let sessions = computed(() => {
      return store.getters[Modules.WORLD_SESSION + "getSessions"];
    });
    let sessionsMeta = computed(() => {
      return store.getters[Modules.WORLD_SESSION + "getMeta"];
    });

    onMounted(() => {
      setCurrentPageTitle("World Sessions");
    });

    fetchRecords();

    function refresh() {
      fetchRecords();
    }

    function searchNow() {
      currentPageURL.value = "";
      fetchRecords();
    }

    function gotoPage(url) {
      currentPageURL.value = url;
      fetchRecords();
    }

    function fetchRecords() {
      store.dispatch(Modules.WORLD_SESSION + Actions.FETCH_SESSIONS, {
        search: search.value,
        live: live.value,
        url: currentPageURL.value,
      });
    }

    watch(live, (value) => {
      currentPageURL.value = "";
      fetchRecords();
    });

    return {
      formatDateTime,
      search,
      isLoading,
      sessions,
      sessionsMeta,
      live,
      refresh,
      searchNow,
      gotoPage,
    };
  },
});
